'use strict';

module.exports = {
    addRecaptchaToForm: function (form, action) {
        if (typeof grecaptcha === 'undefined') {
            return;
        }

        grecaptcha.ready(function () {
            grecaptcha.execute('6LdJtXcUAAAAAAE4_hAmgDegPjgFGQ6kno3Cqp5Y', { action: action })
                .then(function (token) {
                    $(form).find('input[name="g-recaptcha-response"]').remove();

                    $('<input>').attr({
                        type: 'hidden',
                        name: 'g-recaptcha-response',
                        value: token
                    }).appendTo(form);
                });
        });
    },
    generateToken: function (action) {
        if (typeof grecaptcha === 'undefined') {
            return Promise.resolve();
        }

        return grecaptcha.execute('6LdJtXcUAAAAAAE4_hAmgDegPjgFGQ6kno3Cqp5Y', { action: action });
    }
};
