'use strict';

module.exports = function (url, params) {
    let newUrl = url;

    if (Object.keys(params).length) {
        newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
            return key + '=' + encodeURIComponent(params[key]);
        }).join('&');
    }

    return newUrl;
};
