'use strict';

module.exports = {
    init: function () {
        if ($('#back-to-top').length > 0) {
            const scrollTrigger = 200;
            const backToTop = () => {
                var scrollTop = $(window).scrollTop();
                if (scrollTop > scrollTrigger) {
                    $('#back-to-top').addClass('show');
                } else {
                    $('#back-to-top').removeClass('show');
                }
            };

            backToTop();

            let hasScrolled = false;
            $(window).on('scroll', () => {
                hasScrolled = true;
            });
            setInterval(() => {
                if (!hasScrolled) return;

                hasScrolled = false;
                backToTop();
            }, 250);

            $('#back-to-top').on('click', (e) => {
                e.preventDefault();
                $('html,body').animate({
                    scrollTop: 0
                }, 700);
            });
        }
    }
};
