'use strict';

const { default: PerfectScrollbar } = require('perfect-scrollbar');

module.exports = {
    init: function () {
        var ps = null;
        $('body').on('click', '.custom-dropdown__head', function () {
            var $customDropdown = $(this).parents('.custom-dropdown');
            var $customDropdownBody = $customDropdown.find('.custom-dropdown__content');

            $('.custom-dropdown').not($(this).parent()).removeClass('open').find('.custom-dropdown__content')
                .hide();
            if (ps != null) {
                ps.destroy();
                ps = null; // garbage collection
            }
            $customDropdownBody.toggle();
            $customDropdown.toggleClass('open');

            const id = $customDropdown.attr('id');
            const $customDropdownList = $(`#${id} .custom-dropdown__content ul:not(.ps)`);

            if ($customDropdownList.length > 0) {
                ps = new PerfectScrollbar($customDropdownList[0], { suppressScrollX: true, scrollYMarginOffset: 10 });
            }
        });

        $('body').on('click', '.custom-dropdown__content button', function () {
            var $customDropdown = $(this).parents('.custom-dropdown');
            var $customDropdownBody = $customDropdown.find('.custom-dropdown__content');
            $customDropdownBody.toggle();
            $customDropdown.toggleClass('open');
        });

        $('body').click(function (event) {
            var $target = $(event.target);
            var $customDropdown = $('.custom-dropdown');
            if ($target.parents('.custom-dropdown').length === 0) {
                $customDropdown.removeClass('open').find('.custom-dropdown__content').hide();
                if (ps != null) {
                    ps.destroy();
                    ps = null; // garbage collection
                }
            }
        });

        $('body').on('click', '.custom-dropdown--selected-value .custom-dropdown__content a:not(.size-value):not(.swatch-link)', function () {
            const $this = $(this);

            if ($this.hasClass('disabled')) {
                return;
            }

            const $customDropdown = $this.parents('.custom-dropdown');
            const $header = $customDropdown.find('.custom-dropdown__head');
            const value = $this.text().trim();

            $header.find('.value').remove();

            if (!$this.hasClass('selected')) {
                $header.append(`<span class="value">${value}</span>`);
            }

            $customDropdown.removeClass('open').find('.custom-dropdown__content').hide();
        });

        $('body').on('product:afterAttributeSelect', (e, response) => {
            let $target = $(response.target);
            let $customDropdown;
            if ($target.parents('.custom-dropdown').length) {
                $customDropdown = $target.parents('.custom-dropdown');
            } else if ($target.parents('.mobile-checkout-fixed').length) {
                $customDropdown = $('.product-detail .product-attributes .clothes .custom-dropdown');
            }

            if (!$customDropdown || !$customDropdown.length) return;

            const $header = $customDropdown.find('.custom-dropdown__head');
            $header.find('.value').remove();

            if ($target.hasClass('swatch-link')) {
                $target = $target.find('.swatch-value');
            }
            if ($target.hasClass('selected')) {
                const value = $target.data('attr-display-value');
                $header.append(`<span class="value">${value}</span>`);
            }
            $customDropdown.removeClass('open').find('.custom-dropdown__content').hide();
        });
    }
};
