'use strict';

const SimpleSlider = require('../util/simpleSlider');

const FilterBar = {};

FilterBar.init = () => {
    SimpleSlider.init('.grid-header .filter-bar');
};

FilterBar.initRefinementsFilterBar = () => {
    SimpleSlider.init('.refinements .filter-bar');
};

module.exports = FilterBar;
