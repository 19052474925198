'use strict';

const OpeningHours = {};

OpeningHours.getStartDayIndex = () => {
    const today = new Date();
    return today.getDay() - 1 > -1 ? today.getDay() - 1 : 6;
};

OpeningHours.initRollingWeek = () => {
    const $openingHours = $('.opening-hours');
    let startDayIndex = OpeningHours.getStartDayIndex();
    for (let index = 0; index < $openingHours.length; index++) {
        const $openingHour = $($openingHours[startDayIndex]);
        $openingHour.addClass(`order-${index}`);
        if (++startDayIndex > 6) {
            startDayIndex = 0;
        }
    }
};

OpeningHours.setOpeningHourHtml = ($hourContainer, classToAdd, hourString, openingHour) => {
    let modifiedHourString = hourString;
    if (openingHour) {
        const hours = openingHour.split(':')[0];
        const minutes = openingHour.split(':')[1] === '00' ? '' : openingHour.split(':')[1];
        modifiedHourString = modifiedHourString.replace('{0}', hours).replace('{1}', minutes);
    }
    $hourContainer.addClass(classToAdd);
    $hourContainer.text(modifiedHourString);
};

/**
 * gets the correct day index which is used by the store model opening hours
 *
 * @param {Date} date - The date to get day index from
 *
 * @return {int} - The corrected day index
 */
OpeningHours.getCorrectDayIndex = (date) => {
    var currentDayIndex = date.getDay() - 1;
    if (currentDayIndex === -1) {
        currentDayIndex = 6;
    }
    return currentDayIndex;
};

OpeningHours.getOpeningHoursForDayIndex = (store, dayIndex) => {
    const dstString = store.isDaylightSavingTimeEnabled ? 'Summer' : 'Winter';
    const openingHours = {
        openingHourFromValue: 0,
        openingHourFromFormatted: '',
        openingHourToValue: 0,
        openingHourToFormatted: '',
        isSpecialClosed: false,
        isSpecialOpen: false
    };

    if (store[`storeSpecialHoursFrom_${dayIndex}`]) {
        openingHours.openingHourFromValue = store[`storeSpecialHoursFrom_${dayIndex}`] ? store[`storeSpecialHoursFrom_${dayIndex}`].value : 2500;
        openingHours.openingHourFromFormatted = store[`storeSpecialHoursFrom_${dayIndex}`] ? store[`storeSpecialHoursFrom_${dayIndex}`].formatted : null;

        if (store[`storeSpecialHoursFrom_${dayIndex}`] && store[`storeSpecialHoursFrom_${dayIndex}`].value === 0) {
            openingHours.isSpecialClosed = true;
        } else {
            openingHours.isSpecialOpen = true;
        }
    } else {
        openingHours.openingHourFromValue = store[`store${dstString}HoursFrom_${dayIndex}`] ? store[`store${dstString}HoursFrom_${dayIndex}`].value : 2500;
        openingHours.openingHourFromFormatted = store[`store${dstString}HoursFrom_${dayIndex}`] ? store[`store${dstString}HoursFrom_${dayIndex}`].formatted : null;
    }

    if (store[`storeSpecialHoursTo_${dayIndex}`]) {
        openingHours.openingHourToValue = store[`storeSpecialHoursTo_${dayIndex}`] ? store[`storeSpecialHoursTo_${dayIndex}`].value : -1;
        openingHours.openingHourToFormatted = store[`storeSpecialHoursTo_${dayIndex}`] ? store[`storeSpecialHoursTo_${dayIndex}`].formatted : null;
    } else {
        openingHours.openingHourToValue = store[`store${dstString}HoursTo_${dayIndex}`] ? store[`store${dstString}HoursTo_${dayIndex}`].value : -1;
        openingHours.openingHourToFormatted = store[`store${dstString}HoursTo_${dayIndex}`] ? store[`store${dstString}HoursTo_${dayIndex}`].formatted : null;
    }
    return openingHours;
};

/**
 * returns todays closing hour of the given store if the store's open, returns null if it's closed
 *
 * @param {Object} store - The store to show the hours of
 *
 * @return {string} The opening hours formatted.
 */
OpeningHours.getTodaysClosingHour = (store) => {
    if (store) {
        const currentDate = new Date();
        const currentDayIndex = OpeningHours.getCorrectDayIndex(currentDate);
        const openingHours = OpeningHours.getOpeningHoursForDayIndex(store, currentDayIndex);
        const currentHourValue = Number(`${currentDate.getHours()}${(currentDate.getMinutes() < 10 ? '0' : '')}${currentDate.getMinutes()}`);
        let nextOpeningHour = null;
        const isOpenNow = currentHourValue >= openingHours.openingHourFromValue && currentHourValue <= openingHours.openingHourToValue;
        let willOpenToday = false;
        let willOpenTomorrow = false;
        if (!isOpenNow) {
            willOpenToday = openingHours.openingHourFromFormatted && currentHourValue < openingHours.openingHourFromValue;
            if (willOpenToday) {
                nextOpeningHour = openingHours.openingHourFromFormatted;
            } else {
                const dayIndexTomorrow = currentDayIndex === 6 ? 0 : currentDayIndex + 1;
                const openingHoursTomorrow = OpeningHours.getOpeningHoursForDayIndex(store, dayIndexTomorrow);
                if (!openingHoursTomorrow.isSpecialClosed) {
                    nextOpeningHour = openingHoursTomorrow.openingHourFromFormatted;
                    willOpenTomorrow = !!nextOpeningHour;
                }
            }
        }
        return {
            hours: isOpenNow ? openingHours.openingHourToFormatted : null,
            nextOpeningHour: nextOpeningHour,
            isSpecialClosed: openingHours.isSpecialClosed,
            isSpecialOpen: openingHours.isSpecialOpen,
            willOpenToday: willOpenToday,
            willOpenTomorrow: willOpenTomorrow
        };
    }
    return {};
};

/**
 * Checks if store is open or closed
 *
 * @param {jQuery} mainDiv - The main div
 * @param {jQuery} openClosedDiv - The open/closed div
 * @param {string} hoursOpenClass - The open hours class
 * @param {string} hoursClosedClass - The closed hours class
 */
OpeningHours.updateTodayOpenUntilHours = (mainDiv, openClosedDiv, hoursOpenClass, hoursClosedClass) => {
    $(mainDiv).each(function (index, store) {
        const $store = $(store);
        const $hour = $store.find(openClosedDiv);
        const storeObject = $hour.data('store');
        const closingHour = OpeningHours.getTodaysClosingHour(storeObject);
        const willOpenToday = closingHour.willOpenToday && storeObject.storeActive;
        const willOpenTomorrow = closingHour.willOpenTomorrow && storeObject.storeActive;

        if (storeObject && storeObject.storeActive && closingHour.hours) {
            const hourOpenString = closingHour.isSpecialOpen ? $hour.data('specialhouropenstring') : $hour.data('houropenstring');
            OpeningHours.setOpeningHourHtml($hour, hoursOpenClass, hourOpenString, closingHour.hours);
        } else if (willOpenToday || willOpenTomorrow) {
            const hourWillOpenString = closingHour.willOpenToday ? $hour.data('will-open-today-hour-string') : $hour.data('will-open-tomorrow-hour-string');
            if (!hourWillOpenString) return;
            OpeningHours.setOpeningHourHtml($hour, hoursOpenClass, hourWillOpenString, closingHour.nextOpeningHour);
        } else {
            const hourClosedString = closingHour.isSpecialClosed ? $hour.data('specialhourclosedstring') : $hour.data('hourclosedstring');
            OpeningHours.setOpeningHourHtml($hour, hoursClosedClass, hourClosedString, null);
        }
    });
};

OpeningHours.updateOpeningHoursStoreDetail = () => {
    const storeObject = $('.store-detail').data('store');
    const winterSummerString = storeObject.isDaylightSavingTimeEnabled ? 'Summer' : 'Winter';

    $('.opening-hours__day').each(function (index, day) {
        const $day = $(day);
        if (index === OpeningHours.getCorrectDayIndex(new Date())) {
            if (OpeningHours.getTodaysClosingHour(storeObject).hours) {
                $day.addClass('opening-hours__hour--open');
            } else {
                $day.addClass('opening-hours__hour--closed');
            }
        }
    });

    let hasSpecialHours = false;

    $('.opening-hours__hour--open-closed').each(function (index, hour) {
        const $hour = $(hour);

        if (storeObject[`storeSpecialHoursFrom_${index}`]) {
            hasSpecialHours = true;
            $hour.text($hour.data('houropenstring'));
        } else if (storeObject[`store${winterSummerString}HoursFrom_${index}`]) {
            $hour.text($hour.data('houropenstring'));
        } else {
            $hour.text($hour.data('hourclosedstring'));
        }

        if (index === OpeningHours.getCorrectDayIndex(new Date())) {
            if (OpeningHours.getTodaysClosingHour(storeObject).hours) {
                $hour.addClass('opening-hours__hour--open');
                $hour.parents('.row.opening-hours').first().addClass('opening-hours__hour--open--border');
                $hour.parents('.opening-hours-container__opening-hours').first().find('.store-detail__special-hours-day').addClass('opening-hours__hour--open');
            } else {
                $hour.addClass('opening-hours__hour--closed');
                $hour.parents('.row.opening-hours').first().addClass('opening-hours__hour--closed--border');
            }
        }
    });

    if (hasSpecialHours) {
        $('.store-detail__special-hours').removeClass('d-none');
        $('.js-special-stores-hours-message').show();
    }
};

module.exports = OpeningHours;
