'use strict';

const processInclude = require('base/util');

/**
 * Example:
 *  var Dialog = require('./util/dialog');
 *  Dialog.show(Dialog.TYPE_SUCCESS, 'Title', 'Description', {
 *          accept: {
 *              text: 'OK',
 *              callback: function() {
 *                  alert('blah');
 *              }
 *          },
 *          cancel: {
 *              text: 'meh'
 *          }
 *      }
 *  );
 */

const closeOtherModals = () => {
    $('body').on('show.bs.modal', '#confirmationDialog', function () {
        $('.modal.show').modal('hide');
        // remove the listener after firing, since the listener will be bound everytime a dialog is initialised and we want to prevent having multiple listeners
        $('body').off('show.bs.modal', '#confirmationDialog');
    });
};

module.exports = {
    TYPE_ERROR: 0,
    TYPE_SUCCESS: 1,
    TYPE_CONFIRM: 2,
    TYPE_RAVOTCLUB_INFO: 3,
    show: function (type, title, message, buttonConfig, selector, html, scripts, messageIsHtml) {
        closeOtherModals();

        var $confirmationDialogModal = $('#confirmationDialog');
        var $footer = $confirmationDialogModal.find('.modal-footer');
        var $acceptButton = $confirmationDialogModal.find('.modal-footer .btn-primary');
        var $closeButton = $confirmationDialogModal.find('.modal-footer .btn[data-dismiss="modal"]');
        var $body = $confirmationDialogModal.find('.modal-body__container');
        var $message = $confirmationDialogModal.find('.modal-body p');
        var $title = $confirmationDialogModal.find('.modal-title');

        if (type === this.TYPE_SUCCESS) {
            $title.addClass('title--confirm');
        }

        if (type === this.TYPE_ERROR) {
            $title.addClass('title--error');
        }

        if (type === this.TYPE_CONFIRM) {
            $title.addClass('title--confirm');
        }

        if (type === this.TYPE_RAVOTCLUB_INFO) {
            $confirmationDialogModal.addClass('modal--ravot-club-info');
            $title.addClass('d-none');
            $footer.addClass('d-none');
            $message.addClass('d-none');
        }

        if (title && title.length > 0) {
            $title.text(title);
        }

        if (message && message.length > 0) {
            $message.text(message);
        }

        if (selector) {
            let $element = $(selector);
            if ($element.length > 0) {
                $body.html($element.html());
                $body.show();
                $footer.hide();
            }
        } else {
            $body.hide();
            $footer.show();
            if (message && message.length > 0) {
                if (messageIsHtml) {
                    $message.html(message);
                } else {
                    $message.text(message);
                }
            }

            if (html) {
                $body.html(html);
                $body.show();
            }

            if (buttonConfig) {
                if (buttonConfig.cancel) {
                    if (buttonConfig.cancel.icon) {
                        $closeButton.find('.btn__inner').html("<i class='fa " + buttonConfig.cancel.icon + "'></i> " + buttonConfig.cancel.text);
                    } else {
                        $closeButton.find('.btn__inner').text(buttonConfig.cancel.text);
                    }

                    $closeButton.unbind('click').on('click', function () {
                        $confirmationDialogModal.modal('hide');

                        if (buttonConfig.cancel.callback) {
                            buttonConfig.cancel.callback();
                        }
                    });
                } else {
                    $closeButton.hide();
                }

                if (buttonConfig.accept) {
                    if (buttonConfig.accept.icon) {
                        $acceptButton.find('.btn__inner').html("<i class='fa " + buttonConfig.accept.icon + "'></i> " + buttonConfig.accept.text);
                    } else {
                        $acceptButton.find('.btn__inner').text(buttonConfig.accept.text);
                    }

                    $acceptButton.unbind('click').on('click', function () {
                        $confirmationDialogModal.modal('hide');

                        if (buttonConfig.accept.callback) {
                            buttonConfig.accept.callback();
                        }
                    });
                } else {
                    $acceptButton.hide();
                }

                if (buttonConfig.accept && !buttonConfig.cancel) {
                    $acceptButton.parent().removeClass('col-lg-6').addClass('col-lg-12');
                } else if (!buttonConfig.accept && buttonConfig.cancel) {
                    $acceptButton.parent().remove();
                    $closeButton.parent().removeClass('col-lg-6').addClass('col-lg-12');
                }
            } else {
                $footer.addClass('d-none');
            }
        }

        $confirmationDialogModal.modal('show');

        if (scripts) {
            scripts.forEach(script => {
                processInclude(script);
            });
        }
    },
    hide: function () {
        var $confirmationDialogModal = $('#confirmationDialog');
        $confirmationDialogModal.modal('hide');
    },
    onClose: function (callback) {
        $('#confirmationDialog').on('hidden.bs.modal', function () {
            callback();
        });
    }
};
