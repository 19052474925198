'use strict';

const appendToUrl = require('../util/appendToUrl');
const isMobile = require('../util/isMobile');
const processInclude = require('base/util');

const ClickAndCollect = {};

ClickAndCollect.init = () => {
    ClickAndCollect.onAfterAttributeSelect();
    ClickAndCollect.onProductCheckSupply();
};

// TODO: refactor  init into one function after completion full redesign
ClickAndCollect.initRedesign = () => {
    ClickAndCollect.onAfterAttributeSelectRedesign();
    ClickAndCollect.onProductCheckSupply();
};

ClickAndCollect.onAfterAttributeSelect = () => {
    $('body').on('product:afterAttributeSelect', (e, response) => {
        const isClickAndCollectEnabled = $('.js-product-detail').data('click-and-collect-enabled');
        if (!isClickAndCollectEnabled) return;

        const product = response && response.data ? response.data.product : null;
        const $productContainer = response.container;
        if (!product || !$productContainer || !$productContainer.length) return;

        const isWishlistPage = $('.js-add-to-cart-container').data('is-wishlist-page');
        $productContainer.find('.js-add-to-cart-container').spinner().start(true);
        $productContainer.find('.js-add-to-cart-container-mobile-reservation').prop('hidden', !product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-add-to-cart-container-mobile-add').prop('hidden', product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-add-to-cart').prop('disabled', product.onlyAvailableForPurchaseAsBonusProduct || product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-product-check-supply-primary').toggleClass('d-none d-md-block', !product.hasOnlyClickAndCollectEnabled && !isWishlistPage);
        $productContainer.find('.js-product-check-supply-primary').prop('disabled', product.isWebExclusive || !product.availableForReservation);
        $productContainer.find('.js-product-check-supply-secondary').prop('hidden', product.isWebExclusive || product.hasOnlyClickAndCollectEnabled || isWishlistPage || !product.availableForReservation);
        $productContainer.find('.js-usp-container-no-click-and-collect-enabled').prop('hidden', product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-usp-container-click-and-collect-enabled').prop('hidden', !product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-add-to-cart-label-not-online').prop('hidden', !product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-mobile-checkout-product-availability-container').prop('hidden', product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-mobile-checkout-store-only-label').prop('hidden', !product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-product-availability-container').toggleClass('d-md-block', !product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-wishlist-availability-container').prop('hidden', product.hasOnlyClickAndCollectEnabled);

        let url = $productContainer.find('.js-click-and-collect-label-container').data('url');
        if (!url) return;

        url = appendToUrl(url, {
            pid: product.id
        });
        $.ajax({
            url: url,
            type: 'GET',
            success: (data) => {
                var $errorLabel = $productContainer.find('.js-click-and-collect-label-container');
                if (!$errorLabel.hasClass('set-details')) $errorLabel.html(data);
                $productContainer.find('.js-add-to-cart-container').spinner().stop();
            },
            error: () => {
                $productContainer.find('.js-add-to-cart-container').spinner().stop();
            }
        });
    });
};

// TODO: refactor both onAfterAttributeSelect functions to one after complete redesign
ClickAndCollect.onAfterAttributeSelectRedesign = () => {
    $('body').on('product:afterAttributeSelect', (e, response) => {
        const isClickAndCollectEnabled = $('.js-product-detail').data('click-and-collect-enabled');
        if (!isClickAndCollectEnabled) return;

        const product = response && response.data ? response.data.product : null;
        const $productContainer = response.container;
        if (!product || !$productContainer || !$productContainer.length) return;

        $productContainer.find('.js-add-to-cart').prop('disabled', product.onlyAvailableForPurchaseAsBonusProduct || product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-add-to-cart-container').toggleClass('d-none', product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.product-check-supply').prop('disabled', product.isWebExclusive || !product.availableForReservation);

        if ($('.product-set-detail').length) {
            if (isMobile.any()) {
                $productContainer.find('.js-product-check-supply-container').toggleClass('d-none', !product.hasOnlyClickAndCollectEnabled || product.isWebExclusive || !product.availableForReservation);
            } else {
                $productContainer.find('.js-product-check-supply-container')
                    .toggleClass('d-none', product.isWebExclusive || !product.availableForReservation || !product.hasOnlyClickAndCollectEnabled)
                    .toggleClass('d-lg-block', !product.isWebExclusive && product.availableForReservation && !product.hasOnlyClickAndCollectEnabled)
                    .toggleClass('col-lg-5 pr-lg-2', product.hasOnlyClickAndCollectEnabled)
                    .toggleClass('col-lg-4 pl-lg-2', !product.hasOnlyClickAndCollectEnabled);
            }
            $productContainer.find('.product-check-supply')
                .toggleClass('btn-primary', product.hasOnlyClickAndCollectEnabled)
                .toggleClass('btn-quinary', !product.hasOnlyClickAndCollectEnabled);

            return;
        }

        $productContainer.find('.js-add-to-cart-usp-container').toggleClass('d-none', product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-product-check-supply-container').toggleClass('d-none', !product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-product-check-supply-usp-container').toggleClass('d-none', !product.hasOnlyClickAndCollectEnabled);
        $productContainer.find('.js-product-availability-container').toggleClass('d-none', product.hasOnlyClickAndCollectEnabled);

        const $reservationButtonHolder = $productContainer.find('.js-product-detail-reservation-message');
        const hideReservationButtonHolder = product.hasAllVariantsNoOfflineStock || product.hasOnlyClickAndCollectEnabled || product.isWebExclusive || !product.availableForReservation;
        $reservationButtonHolder.toggleClass('d-none', hideReservationButtonHolder);

        $productContainer.find('.js-product-detail-click-and-collect-only-message').toggleClass('d-none', !product.hasOnlyClickAndCollectEnabled || product.isWebExclusive || !product.availableForReservation);

        const hasSelectedSize = $productContainer.find('.size-value.selected').length > 0;
        $reservationButtonHolder.find('.js-product-detail-reservation-message-no-size').toggleClass('d-none', hasSelectedSize);
        $reservationButtonHolder.find('.js-product-detail-reservation-message-has-size').toggleClass('d-none', !hasSelectedSize || product.offlineStockValue < 1);
        $reservationButtonHolder.find('.js-product-detail-reservation-message-no-stock').toggleClass('d-none', !hasSelectedSize || product.offlineStockValue > 0);
        $reservationButtonHolder.find('.product-check-supply').toggleClass('d-none', !hasSelectedSize || product.offlineStockValue < 1);

        // No api call needed when reservation button is hidden
        if (hideReservationButtonHolder) return;

        let url = $reservationButtonHolder.data('reservation-label-url');
        if (!url) return;

        url = appendToUrl(url, {
            pid: product.id
        });
        $.get(url).done(result => {
            $reservationButtonHolder.find('button').html(result);
        });
    });
};

ClickAndCollect.onProductCheckSupply = () => {
    $('body').on('click', '.product-check-supply', function (e) {
        e.preventDefault();

        const $buttonPress = $(this);
        const $productContainer = $buttonPress.closest('.product-detail');
        let hasError = false;

        $productContainer.find('.product-attributes div[data-attr].swatch').each(function () {
            var $dataThis = $(this);
            var $attrName = $(this).attr('data-attr');
            if ($dataThis.find('span.selected').length === 0 && $dataThis.find('a.selected').length === 0) {
                hasError = true;
                $dataThis.find('.attribute-name').addClass('error');
                $productContainer.find(`p.error-${$attrName}`).removeClass('d-none');
                if ($buttonPress.parents('.mobile-checkout-fixed').length && $attrName === 'size') {
                    $('.mobile-checkout-fixed .size-select-fixed').removeClass('d-none');
                }
            } else {
                $dataThis.find('.attribute-name').removeClass('error');
                $productContainer.find(`p.error-${$attrName}`).addClass('d-none');
            }
        });

        if (hasError) {
            return;
        }

        $('body').trigger('reservation:start', [$(this)]);

        const $modal = $('#clickCollectModal');
        const noReservation = $buttonPress.data('no-reservation');

        const reservationUrl = $modal.data('reservation-dialog');
        const pid = $productContainer.find('.product-id').text() || $productContainer.data('pid');

        $modal.data('pid', pid);

        $.spinner().start();
        $.ajax({
            url: reservationUrl + '?pid=' + pid, // Product-ReservationDialog
            method: 'GET',
            dataType: 'html',
            success: function (html) {
                $modal.find('.clickcollect-modal-body').html(html);

                const $title = $modal.find('.js-clickcollect-modal-title');
                $title.text($title.data('title'));

                require('../inventory/inventoryCheck').init(noReservation);
                processInclude(require('../components/clientSideValidation'));

                $modal.modal('show');
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

module.exports = ClickAndCollect;
