'use strict';

const Dialog = require('../util/dialog');
const Recaptcha = require('../util/recaptcha');
const $wishlistCounter = $('.js-wishlistCount');

/**
 * toggle the products in the wishlist count productlist
 * @param {string} pid - product sku
 */
function toggleProductInWishlist(pid) {
    $.spinner().stop();

    /**
     * @type {Array}
     */
    let products = $wishlistCounter.data('products');

    if (products.indexOf(pid) < 0) {
        products.push(pid);
    } else {
        products.splice(products.indexOf(pid), 1);
    }

    $wishlistCounter.data('products', products);
}

/**
 * Adds the product to the wishlist
 * @param {string} pid - product ID
 * @param {string} url - the url of the endpoint
 * @param {jqueryObj} $heart - the hearticon of the wishlist
 * @param {boolean} isRemove - True if item will be removed, false if it is added to wishlist
 * @param {jqueryObj} $productContainer - the removed or added productContainer (containing the tracking data)
 */
function addProductToWishList(pid, url, $heart, isRemove, $productContainer) {
    Recaptcha.generateToken('product_addtowishlist').then(function (token) {
        $.ajax({
            url: url, // Wishlist-AddProduct
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                'g-recaptcha-response': token
            },
            success: function (data) {
                if (data.success) {
                    toggleProductInWishlist(data.pid);
                    $('.js-wishlistCount').trigger('count:update', $heart.hasClass('active') ? 1 : -1);

                    $('body').trigger('wishlist:trackWishlist', {
                        productsInWishlist: data.productsInWishlist,
                        isRemove: isRemove,
                        productContainer: $productContainer
                    });
                }
            },
            error: function (err) {
                const errData = err.responseJSON;
                Dialog.show(Dialog.TYPE_ERROR, errData.title, errData.msg, {
                    accept: {
                        text: errData.btn,
                        callback: function () {
                            location.href = errData.redirectUrl;
                        }
                    },
                    cancel: {
                        text: errData.cancel
                    }
                });

                $heart.toggleClass('active'); // Revert heart icon
            },
            complete: () => {
                $heart.removeClass('disabled');
            }
        });
    }).catch(function () {
        $.spinner().stop();

        $heart.removeClass('disabled');

        const errMsg = $('.error-messaging').data('uncaught-error-msg');
        const errTitle = $('.error-messaging').data('uncaught-error-title');
        Dialog.show(Dialog.TYPE_ERROR, errTitle, errMsg, {
            accept: {
                text: 'OK'
            }
        });
    });
}

module.exports = {
    processWishlistClick: function () {
        $('body').on('click', '.js-wishlistTile', function (e) {
            e.preventDefault();
            const $this = $(this);

            if ($this.hasClass('disabled')) return;

            $this.addClass('disabled');

            let $productContainer = $(this).parents('.product-info');
            $productContainer = $productContainer.length ? $productContainer : $(this).parents('[data-pid]');

            const isRemove = $this.hasClass('active');
            const url = $this.hasClass('active') ? $this.data('removeurl') : $this.attr('href');
            let pid = ($this.hasClass('js-detail-wishlistTile') && $('.product-id').text().substring(0, 6)) || $this.data('pid');
            pid = pid.toString().substring(0, 6);
            const $heart = $(`.js-wishlistTile[data-pid^="${pid}"]`);

            if (!url || !pid) {
                return;
            }

            $heart.toggleClass('active');

            addProductToWishList(pid, url, $heart, isRemove, $productContainer);
        });
    },
    initUpdateCounter: function () {
        $wishlistCounter.on('count:update', function (e, value) {
            var $this = $(this).find('.counter');
            var currentValue = Number($this.text());

            $this.text(currentValue + value);
        });
    },
    initProducts: function () {
        var products = $wishlistCounter.data('products');
        if (products) {
            products.forEach(function (productSku) {
                const pid = productSku.substring(0, 6);
                $(`.js-wishlistTile[data-pid^="${pid}"]`).addClass('active');
            });
        }
    }
};
