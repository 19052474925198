'use strict';

const SimpleSlider = require('../util/simpleSlider');

const Sidemenu = {};

Sidemenu.init = () => {
    const selector = '#sideMenuSlider';
    SimpleSlider.init(selector);
    SimpleSlider.scrollToSelected(selector, '.sidemenu__category--selected');
};

module.exports = Sidemenu;
