'use strict';

const SimpleSlider = require('../util/simpleSlider');

const SortOrderMenu = {};

SortOrderMenu.init = () => {
    const selector = '.sort-order';
    SimpleSlider.init(selector);
    SimpleSlider.scrollToSelected(selector, '.sort-order__item--selected');
};

module.exports = SortOrderMenu;
