'use strict';

// to search for brands in brand search refinements on product lister pages
const BrandSearch = {};

BrandSearch.searchTerm = '';
BrandSearch.trigger = '';

BrandSearch.init = () => {
    BrandSearch.trigger = 'keyup';
    BrandSearch.onSearch(BrandSearch.trigger);
};

BrandSearch.initKiosk = () => {
    BrandSearch.trigger = 'change';
    BrandSearch.onSearch(BrandSearch.trigger);
};

BrandSearch.onSearch = (trigger) => {
    $('body').on(trigger, '[name="search-brand"]', function () {
        const $this = $(this);
        BrandSearch.searchTerm = $this.val().toLowerCase().trim();
        const filterItems = $this.parents('.refinement').find('.refinement__values .refinement-value');
        if (BrandSearch.searchTerm) {
            filterItems.each((index, filterItem) => {
                const $filterItem = $(filterItem);
                const brandName = $filterItem.find('.refinement-value__name').text().trim().toLowerCase();

                if (brandName && brandName.length > 0 && brandName.indexOf(BrandSearch.searchTerm) !== 0) {
                    $filterItem.hide();
                } else {
                    $filterItem.show();
                }
            });
        } else {
            filterItems.each((index, filterItem) => {
                const $filterItem = $(filterItem);
                $filterItem.show();
            });
        }
    });
};

BrandSearch.setSearchFieldValue = (value) => {
    const $searchField = $('input[name="search-brand"]');
    $searchField.prop('value', value);
};

BrandSearch.search = () => {
    const $searchField = $('input[name="search-brand"]');
    $searchField.trigger(BrandSearch.trigger);
};

module.exports = BrandSearch;
