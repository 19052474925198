'use strict';

const openingHours = require('../util/openingHours');
const formValidation = require('../components/formValidation');
const appendToUrl = require('../util/appendToUrl');
const mapRendererInventoryCheck = require('./mapRendererInventoryCheck');
const Recaptcha = require('../util/recaptcha');
const Dialog = require('../util/dialog');

const InventoryCheck = {};

InventoryCheck.init = (isKiosk) => {
    InventoryCheck.isKiosk = isKiosk;

    openingHours.updateTodayOpenUntilHours('.store-locator-details', '.store-locator-details__hours--open-closed', 'store-locator-details__hours--open', 'store-locator-details__hours--closed');
    mapRendererInventoryCheck.onClickShowMap();

    $('#storeLocatorSearchInput').on('keyup', function (event) {
        if (event.keyCode === 13) {
            $('#storeLocatorSearchButton').trigger('click');
        }
    });

    if (!InventoryCheck.isKiosk) {
        InventoryCheck.onReserveInStoreClick();
        InventoryCheck.onClose();
        InventoryCheck.onLogin();
        InventoryCheck.onConfirmReservation();
    }

    InventoryCheck.onDetectLocation();
    InventoryCheck.onSearch();
};

InventoryCheck.onReserveInStoreClick = () => {
    $('body').on('click', '.results .js-reserve-in-store', function () {
        $('.inventory-check-overview').hide();
        const $modal = $('#clickCollectModal');
        const $title = $modal.find('.js-clickcollect-modal-title');

        let newTitle;
        if ($('.inventory-check').data('is-logged-in')) {
            $('.inventory-check-confirmation').show();
            InventoryCheck.selectedStoreId = $(this).data('store-id');
            newTitle = $title.data('title-confirm');
            const name = $(this).parent().find('.store-locator-details__name')
                .text()
                .trim();
            const isFav = $(this).closest('#fav-store').length > 0 || $(this).parent().find('input[name="fav-store"]').is(':checked');
            $('body').trigger('reservation:step2', [name, isFav]);
        } else {
            InventoryCheck.selectedStoreId = $(this).data('store-id');
            $('.inventory-check-login').show();
            newTitle = $title.data('title-login');
        }

        $title.text(newTitle);
    });
};

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
InventoryCheck.updateStoresResults = (data) => {
    const $resultsDiv = $('.results');
    const hasResults = data.stores.length > 0;

    if (!hasResults) {
        $('.store-locator__no-results').show();
    } else {
        $('.store-locator__no-results').hide();
    }

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
        openingHours.updateTodayOpenUntilHours('.store-locator-details', '.store-locator-details__hours--open-closed', 'store-locator-details__hours--open', 'store-locator-details__hours--closed');
        if (InventoryCheck.isKiosk) {
            $('.results .btn-primary').remove();
        }
    }

    $('.inventory-results').scrollTop(0);

    const closestFoundStore = data.stores.find(store => {
        return store.isClosestFoundStore;
    });
    if (!closestFoundStore) return;

    mapRendererInventoryCheck.updateActiveMarker(closestFoundStore.ID);
};

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
InventoryCheck.search = (element) => {
    $.spinner().start();
    const $form = element.closest('.store-locator');
    const postalCode = $form.find('[name="postalCode"]').val();
    const country = $('#location-holder').data('country');
    let googleMapsUrl = $('#storeLocatorSearchInput').data('googlemapsurl');
    const googleMapsUrlParams = {
        address: postalCode + ' ' + country
    };
    googleMapsUrl = appendToUrl(googleMapsUrl, googleMapsUrlParams);

    $('#storeLocatorSearchInput').val('');

    $.ajax({
        url: googleMapsUrl,
        type: 'get',
        success: function (data) {
            const location = data.results[0] ? data.results[0].geometry.location : {};
            const payload = $form.is('form') ? $form.serialize() : { postalCode: postalCode, lat: location.lat, long: location.lng };
            const radius = 50;
            let url = $form.data('action-inventory');
            const productID = $('#clickCollectModal').data('pid');
            const urlParams = {
                radius: radius,
                isInventory: true,
                productID: productID
            };
            url = appendToUrl(url, urlParams);

            $.ajax({
                url: url,
                type: $form.attr('method'),
                data: payload,
                dataType: 'json',
                success: function (resultData) {
                    $.spinner().stop();
                    InventoryCheck.updateStoresResults(resultData);
                }
            });
        }
    });
    return false;
};

InventoryCheck.handleFailReservation = ($title, msg) => {
    $('.inventory-check-fail .inventory-check__text--center').text(msg);
    $('.inventory-check-confirmation').hide();
    $('.inventory-check-fail').show();

    const title = $title.data('title-fail');
    $title.text(title);
    $('body').trigger('reservation:error', [title, msg]);
};

/**
 * This will send an ajax request to confirm the reservation
 */
InventoryCheck.confirmReservation = function () {
    let url = $(this).data('check-user-may-reserve-product-url');
    let postReservationUrl = $(this).data('post-reservation-url');
    const $modal = $('#clickCollectModal');
    const $title = $modal.find('.js-clickcollect-modal-title');

    const $buttonContainer = $('.inventory-check__fixed-bottom-button');

    const $csrf = $buttonContainer.find('input[type=hidden]').first();
    const csrfTokenName = $csrf.attr('name');
    const csrfToken = $csrf.val();

    $.ajax({
        url: url, // Account-CheckUserMayReserveProduct
        type: 'get',
        success: function (data) {
            if (data.success) {
                const errorMessage = $('.inventory-check-overview').data('error-message');
                const productId = $modal.data('pid');
                postReservationUrl = appendToUrl(postReservationUrl, {
                    productId: productId,
                    storeId: InventoryCheck.selectedStoreId
                });
                $.spinner().start();
                $.ajax({
                    url: postReservationUrl, // Product-PostReservation
                    type: 'post',
                    data: {
                        [csrfTokenName]: csrfToken
                    },
                    success: function (res) {
                        $.spinner().stop();
                        if (res.success) {
                            $('.inventory-check-confirmation').hide();
                            $('.inventory-check-reservation-complete').show();

                            const title = $title.data('title-complete');
                            $title.text(title);

                            $('body').trigger('reservation:confirm');
                        } else {
                            InventoryCheck.handleFailReservation($title, errorMessage);
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                        InventoryCheck.handleFailReservation($title, errorMessage);
                    }
                });
            } else {
                InventoryCheck.handleFailReservation($title, data.message);
            }
        }
    });
};

/**
 * This will replace the current confirmation template with a new one, so that it has a new csrf token
 */
InventoryCheck.reinitializeConfirmation = () => {
    let url = $('.inventory-check-login').data('action-get-confirmation');
    const productID = $('#clickCollectModal').data('pid');
    const urlParams = {
        pid: productID
    };

    url = appendToUrl(url, urlParams);

    $.spinner().start(true);

    $.ajax({
        url: url, // Product-Confirmation
        type: 'get',
        success: function (data) {
            $('.container.inventory-check-confirmation').replaceWith(data);
            $('.container.inventory-check-confirmation').find('#confirmReservation').one('click', InventoryCheck.confirmReservation);
            $('.inventory-check-confirmation').show();
            $.spinner().stop();
        },
        error: function () {
            $('.inventory-check-fail').show();
            $.spinner().stop();
        }
    });
};

InventoryCheck.onClose = () => {
    $('.inventory-check-reservation-complete, .inventory-check-fail__button').on('click', function () {
        $('#clickCollectModal').modal('hide');
        window.location.reload();
    });
};

InventoryCheck.onDetectLocation = () => {
    // clicking on detect location.
    $('.detect-location').on('click', function () {
        $.spinner().start();
        const $detectLocationButton = $('.detect-location');
        const productID = $('#clickCollectModal').data('pid');

        const detectLocationSearch = (url, urlParams) => {
            const urlWithParams = appendToUrl(url, urlParams);
            $.ajax({
                url: urlWithParams,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $.spinner().stop();
                    InventoryCheck.updateStoresResults(data);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        };

        if (InventoryCheck.isKiosk) {
            const url = $detectLocationButton.data('action-inventory-kiosk');
            const radius = 50;
            const kioskStoreId = localStorage.getItem('kioskStoreId');
            const urlParams = {
                isInventory: true,
                radius: radius,
                kioskStoreId: kioskStoreId,
                productID: productID
            };
            detectLocationSearch(url, urlParams);

            return;
        }

        if (!navigator.geolocation) {
            $.spinner().stop();

            return;
        }

        navigator.geolocation.getCurrentPosition(
            function (position) {
                const url = $detectLocationButton.data('action-inventory');
                const radius = 50;
                const urlParams = {
                    isInventory: true,
                    radius: radius,
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                    productID: productID
                };
                detectLocationSearch(url, urlParams);
            },
            function () {
                $.spinner().stop();
            }
        );
    });
};

InventoryCheck.onSearch = () => {
    $('.store-locator-container form.store-locator').on('submit', function (e) {
        e.preventDefault();
        InventoryCheck.search($(this));
    });
    $('.store-locator-container .btn-storelocator-search[type="button"]').on('click', function (e) {
        e.preventDefault();
        InventoryCheck.search($(this));
    });
};

InventoryCheck.onLogin = () => {
    $('form.login').on('submit', function (e) {
        e.preventDefault();
        const form = $(this);
        const url = $('.inventory-check-login').data('action');
        form.spinner().start();
        $(document).trigger('login:submit', {
            $form: form
        });

        Recaptcha.generateToken('reservation_login').then(function (token) {
            let requestData = form.serialize();
            requestData += '&g-recaptcha-response=' + token;

            $.ajax({
                url: url,
                type: 'post',
                data: requestData,
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $(document).trigger('login:error', data);
                    } else {
                        $(document).trigger('login:success', data);
                        $(document).trigger('login-reservation:success', data);
                        $('.inventory-check-login').hide();
                        InventoryCheck.reinitializeConfirmation();

                        const $modal = $('#clickCollectModal');
                        const $title = $modal.find('.js-clickcollect-modal-title');
                        $title.text($title.data('title-confirm'));
                    }
                },
                error: function (data) {
                    if (data.responseJSON && data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $(document).trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
        }).catch(function () {
            $.spinner().stop();

            const errMsg = $('.error-messaging').data('uncaught-error-msg');
            const errTitle = $('.error-messaging').data('uncaught-error-title');
            Dialog.show(Dialog.TYPE_ERROR, errTitle, errMsg, {
                accept: {
                    text: 'OK'
                }
            });
        });

        return false;
    });
};

InventoryCheck.onConfirmReservation = () => {
    $('#confirmReservation').one('click', InventoryCheck.confirmReservation);
};

module.exports = InventoryCheck;
