'use strict';

const base = require('base/search/search');
const isMobile = require('../util/isMobile');
const BrandSearch = require('../search/brandSearch');
const appendToUrl = require('../util/appendToUrl');
const FilterBar = require('./filterBar');
const SortOrderMenu = require('./sortOrderMenu');
const noUiSlider = require('nouislider');
const PageFlyout = require('../util/pageFlyout');
const Dialog = require('../util/dialog');
const WishlistHeart = require('../product/wishlistHeart');

const Search = {};

var isSearching = false;

Search.init = () => {
    Search.sharedInitFunctions();
    Search.pageSize(24);
    Search.initTileImageScrollAnimation();
};

Search.sharedInitFunctions = () => {
    base.filter();
    base.closeRefinments();
    base.resize();
    Search.sort();
    Search.disableDisabledAnchor();
    Search.showMore();
    Search.applyFilter();
    Search.initErrorImage();
    Search.initHistory();
    Search.initRefinements();
    Search.showContent();
};

Search.initRefinements = () => {
    const $filterButton = $('.search-products__filter-button');
    const url = $filterButton.data('refinements-url');
    $filterButton.on('click', () => {
        const refinementsSelector = '.js-refinements-flyout';
        if ($(refinementsSelector).length) return;

        $.get(url, (data) => {
            $('.search-products').append(data);
            Search.initPriceRangeSlider();
            PageFlyout.init(
                refinementsSelector,
                '.search-products__filter-button',
                () => {
                    FilterBar.initRefinementsFilterBar();
                    SortOrderMenu.init();
                },
                '.refinements__show-results',
                null
            );
            PageFlyout.open(refinementsSelector, null);
        });
    });
};

Search.initTileImageScrollAnimation = () => {
    if (!isMobile.any()) return;

    const $firstProductTile = $('.product-tile').first();
    if (!$firstProductTile.length || $firstProductTile.find('.tile-image').length < 2 || !$firstProductTile.find('.tile-image-slider').length) return;

    const $slider = $firstProductTile.find('.tile-image-slider');
    $slider.addClass('tile-image-slider--no-snap');
    $slider.scrollLeft(Math.round($firstProductTile.find('.tile-image').width() / 2));
    setTimeout(() => {
        $slider.scrollLeft(0);
        setTimeout(() => {
            $slider.removeClass('tile-image-slider--no-snap');
        }, 200);
    }, 750);
};

Search.initPriceRangeSlider = () => {
    const $priceRangeSlider = $('#price-range-slider');
    if (!$priceRangeSlider.length) return;

    const min = $priceRangeSlider.data('min');
    const max = $priceRangeSlider.data('max');
    const permalinkUrl = new URL($('.permalink').val());
    const startValues = [parseInt(permalinkUrl.searchParams.get('pmin'), 10) || min, parseInt(permalinkUrl.searchParams.get('pmax'), 10) || max];
    noUiSlider.create($priceRangeSlider[0], {
        start: startValues,
        connect: true,
        margin: 1,
        step: 1,
        range: {
            min: min,
            max: max
        }
    });

    $priceRangeSlider[0].noUiSlider.on('update', (values) => {
        $('#priceRefinementMin').prop('value', `€ ${Math.round(values[0])}`);
        $('#priceRefinementMax').prop('value', `€ ${Math.round(values[1])}`);
    });

    $priceRangeSlider[0].noUiSlider.on('set', (values) => {
        $.spinner().start();

        let href = $priceRangeSlider.data('href');
        const url = new URL(href);
        const searchParams = url.searchParams;
        searchParams.set('pmin', values[0]);
        searchParams.set('pmax', values[1]);

        href = `${url.origin}${url.pathname}?${searchParams.toString()}`;

        if (!isSearching) Search.getSearchResults(null, href, true);
    });
};

Search.initFamilyMemberRefinement = () => {
    if ($('#safeSizeLogin').length > 0 && $('#safeSizeLogin').data('active-family-member-refinement') && $('#safeSizeLogin').data('active-family-member-refinement').isFamilyRefinement) {
        let url = $('#safeSizeLogin').data('active-family-member-refinement').values[0].url;
        url = window.location.href + (window.location.href.indexOf('?') !== -1 ? '&' : '?') + url.substring(url.indexOf('?') + 1);
        $.spinner().start();
        Search.getSearchResults(null, url, false);
    }
};

/**
 *scrolls to the top of the PLP
 *
 */
Search.scrollToTop = () => {
    if (!$('.product-grid').length) return;

    $('html, body').animate({
        scrollTop: ($('.product-grid').offset().top - 325)
    }, 500);
};

Search.pushHistory = () => {
    let permalink = $('.permalink').val();
    if (isMobile.any()) {
        const url = new URL(permalink);
        const searchParams = url.searchParams;
        const start = parseInt(searchParams.get('start'), 10);
        const size = parseInt(searchParams.get('sz'), 10);
        if (start > 0) {
            const newSize = start + size;
            searchParams.set('start', '0');
            searchParams.set('sz', newSize.toString());
            permalink = `${url.origin}${url.pathname}?${searchParams.toString()}`;
        }
    }
    history.pushState({ reapplyFilters: true }, document.title, permalink);
};

/**
 * Updates the products count upwards based on the page size.
 */
Search.updateProductsCount = () => {
    const $productsCount = $('.js-productscount');
    $productsCount.text($('.grid-footer').data('count'));
};

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
Search.getContent = ($element, $target) => {
    const showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
};

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
Search.updateDom = ($results, selector) => {
    const $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
};

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
Search.handleRefinements = ($results) => {
    const $updatedResults = $results;
    const $currentRefinements = $('.refinements');
    const $updatedRefinements = $updatedResults.find('.refinements');
    const scrollPosition = $currentRefinements.find('.refinements__container').scrollTop();

    if ($currentRefinements.find('.page-flyout__content').hasClass('show')) {
        $updatedRefinements.find('.page-flyout__content').addClass('show');
    }

    $currentRefinements.find('.refinement').each((i, refinement) => {
        const $refinement = $(refinement);
        const refinementID = $refinement.prop('id');
        const $updatedRefinement = $updatedRefinements.find(`#${refinementID}`);
        if ($refinement.find('.collapse').hasClass('show')) {
            $updatedRefinement.find('.btn[data-toggle="collapse"]').removeClass('collapsed');
            $updatedRefinement.find('.collapse').addClass('show');
        }
    });

    Search.updateDom($updatedResults, '.refinements');

    $('.refinements .refinements__container').scrollTop(scrollPosition);
};

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
Search.parseResults = (response) => {
    const $results = $(response);
    const specialHandlers = {
        '.refinements': Search.handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header-bar-mobile',
        '.header.page-title',
        '.product-grid',
        '.show-more-products',
        '.no-results',
        '.amount-found-items',
        '.js-seo-content',
        '.js-target-audience-buttons',
        '.filter-item-total'
    ].forEach(function (selector) {
        Search.updateDom($results, selector);
    });

    // copyPagination();
    // Search.scrollToTop();

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });

    FilterBar.init();
    FilterBar.initRefinementsFilterBar();
    SortOrderMenu.init();
    Search.initPriceRangeSlider();
};

Search.getActiveFamilyMemberParams = ($this, data) => {
    const newData = data;
    let $activeFamilyMember;
    let activeFamilyMemberName;
    let activeFamilyMemberID;
    if ($this && $this.parents('.js-family').length) {
        $activeFamilyMember = $this.parents('.js-family');
        if ($activeFamilyMember.hasClass('selected')) {
            $activeFamilyMember = null;
            newData.resetRefinementAttributeId = 'size';
        }
    } else if ($('.js-family.selected').length) {
        if (!$this || !$this.parent().hasClass('filter-bar__value--remove-all')) {
            $activeFamilyMember = $('.js-family.selected');
            const filterValueObject = $this && $this.parent('.filter-value').data('object');
            const activeFamilyMemberSize = $activeFamilyMember.data('size');
            const activeFamilyMemberSizeString = activeFamilyMemberSize && activeFamilyMemberSize.toString().replace('.', ',');
            const selectedSize = $this && $this.hasClass('size-button') ? $this.find('span').html() : null;
            if ((filterValueObject && filterValueObject.value === activeFamilyMemberSizeString) || (selectedSize === activeFamilyMemberSizeString)) {
                $activeFamilyMember = null;
            }
        }
    }
    if ($activeFamilyMember && $activeFamilyMember.length) {
        activeFamilyMemberName = $activeFamilyMember.find('.refinement-value__name').text().trim();
        activeFamilyMemberID = $activeFamilyMember.data('familymemberid');
    }
    if (typeof activeFamilyMemberName !== 'undefined' && typeof activeFamilyMemberID !== 'undefined') {
        newData.activeFamilyMemberName = activeFamilyMemberName;
        newData.activeFamilyMemberID = activeFamilyMemberID;
    }

    return newData;
};

/**
 * Retrieve filter search results.
 *
 * @param {Object} $this - jquery element
 * @param {string} url - the URL to call
 * @param {boolean} pushState - Wether or not to push
 */
Search.getSearchResults = ($this, url, pushState) => {
    isSearching = true;
    let params = {
        page: $('.grid-footer').data('page-number'),
        selectedUrl: url
    };
    if (url.indexOf('activeFamilyMember') < 0) {
        params = Search.getActiveFamilyMemberParams($this, params);
    }
    $.ajax({
        url: url,
        data: params,
        method: 'GET',
        success: function (response) {
            if (response.noResults) {
                $.ajax({
                    url: $('.js-noResults').data('noresults'), // Search-NoResults
                    data: {
                        filters: response.preferences
                    },
                    success: function (data) {
                        Dialog.show(Dialog.TYPE_ERROR, null, null, {}, null, data, null, false);
                    }
                });
            } else {
                const activeFilter = ($this) ? $this.parents('[data-refinement]').data('refinement') : 'unknown';

                Search.parseResults(response);

                if (pushState) {
                    Search.pushHistory();
                }

                if (activeFilter === 'brand') {
                    BrandSearch.setSearchFieldValue(BrandSearch.searchTerm);
                    BrandSearch.search();
                }
            }

            $(document).trigger('search:filter', [$this]); // GDDL - Filter Analytics

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        },
        complete: function () {
            isSearching = false;
        }
    });
};

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
Search.updateSortOptions = (response) => {
    const $tempDom = $('<div>').append($(response));
    const sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
};

// Handle sort order menu selection
Search.sort = () => {
    $(document).on('click', '.js-sort-order-item', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const $this = $(e.currentTarget);
        const targetURL = $this.prop('href');
        $.spinner().start();
        Search.getSearchResults(null, targetURL, true);
    });
};

Search.pageSize = (size) => {
    let lastSizeValue = size;
    $('.container').on('change', '[name=page-size]', function (e) {
        e.preventDefault();

        if (this.value === lastSizeValue) {
            return;
        }

        lastSizeValue = this.value;

        $.spinner().start();

        $(this).trigger('search:pageSize', this.value);

        $.ajax({
            url: this.value,
            data: { selectedUrl: this.value },
            method: 'GET',
            success: function (response) {
                $('.product-grid').empty().html(response);

                const $tempDom = $('<div>').append($(response));
                $('.search-products__count').text($tempDom.find('.grid-footer').data('count'));

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

Search.disableDisabledAnchor = () => {
    $('.container').on('click', '.pagination a.bs-disabled', function (e) {
        e.stopPropagation();
        e.preventDefault();
    });
};

// Show more products
Search.showMore = () => {
    $('.container').on('click', '.pagination a.bs-link, .pagination a.bs-next:not(.bs-disabled), .pagination a.bs-previous:not(.bs-disabled)', function (e) {
        e.preventDefault();
        e.stopPropagation();

        const activeFamilyMemberParams = Search.getActiveFamilyMemberParams(null, {});
        const showMoreUrl = appendToUrl($(this).attr('href'), activeFamilyMemberParams);
        $.spinner().start();
        $.ajax({
            url: showMoreUrl,
            data: { selectedUrl: showMoreUrl },
            method: 'GET',
            success: function (response) {
                $('.product-grid').html(response);
                Search.updateSortOptions(response);
                Search.updateProductsCount();
                WishlistHeart.initProducts();
                Search.scrollToTop();
                $.spinner().stop();
                Search.pushHistory();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('.container').on('click', '.show-more-products button', (e) => {
        e.preventDefault();
        e.stopPropagation();

        const activeFamilyMemberParams = Search.getActiveFamilyMemberParams(null, {});
        const showMoreUrl = appendToUrl($(e.currentTarget).data('url'), activeFamilyMemberParams);
        $.spinner().start();
        $.ajax({
            url: showMoreUrl,
            data: { selectedUrl: showMoreUrl },
            method: 'GET',
            success: function (response) {
                $('.grid-footer').replaceWith(response);
                Search.updateSortOptions(response);
                Search.updateProductsCount();
                WishlistHeart.initProducts();
                Search.pushHistory();
            },
            complete: () => {
                $.spinner().stop();
            }
        });
    });
};

// Handle refinement value selection and reset click
Search.applyFilter = () => {
    $('.container').on('click', '.refinements li a, .refinement-bar a.reset, .filter-value a, .swatch-filter a', function (e) {
        $.spinner().start();
        e.preventDefault();
        e.stopPropagation();

        if (!isSearching) {
            Search.getSearchResults($(this), e.currentTarget.href, true);
        }
    });
};

Search.initErrorImage = () => {
    $('.product-tile__image picture img').on('error', function () {
        $(this).attr('src', $(this).data('404')).siblings('source').attr('srcset', $(this).data('404'));
    });
};

Search.showContent = () => {
    // Display the next page of content results from the search
    $('.container').on('click', '.show-more-content button', function () {
        Search.getContent($(this), $('.cards .cards__content'));
        $('.show-more-content').remove();
    });
};

Search.initHistory = () => {
    $(window).on('popstate', function () {
        $.spinner().start();
        Search.getSearchResults(null, window.location.href, false);
    });
};

module.exports = Search;
