'use strict';

const isMobile = require('./util/isMobile');

$(() => {
    require('./search/search').init();
    require('./search/sideMenu').init();
    require('./search/filterBar').init();
    require('./util/backToTop').init();
    require('./components/dropdown').init();
    require('./search/brandSearch').init();
    require('./product/clickAndCollect').init();
    require('./util/breadcrumbs').initPLP();

    if (!isMobile.any()) {
        require('./search/preload').init();
    }
});
