'use strict';

var images = [];

module.exports = {
    init: function () {
        setTimeout(function () {
            var $swatches = $('.swatch--list');
            $swatches.each(function (i) {
                var $swatch = $(this);
                images[i] = new Image();
                images[i].srcset = $swatch.data('hover-image-src');
            });
        }, 0);
    }
};
