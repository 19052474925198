'use strict';

const IsMobile = require('../util/isMobile');

const SimpleSlider = {};

SimpleSlider.init = (selector) => {
    const $simpleSlider = $(selector);
    const $slider = $simpleSlider.find('.simple-slider__items');
    if (!$slider.length) return;

    const sliderElement = $slider[0];
    if (sliderElement.scrollWidth <= sliderElement.clientWidth) return;

    const $arrowLeft = $simpleSlider.find('.simple-slider__slider-arrow--left');
    const $arrowRight = $simpleSlider.find('.simple-slider__slider-arrow--right');

    SimpleSlider.initSliderArrows($slider, $arrowLeft, $arrowRight);
    SimpleSlider.onScrollEvent($slider, $arrowLeft, $arrowRight);
};

SimpleSlider.initSliderArrows = ($slider, $arrowLeft, $arrowRight) => {
    if (IsMobile.mobileOrTablet()) return;

    SimpleSlider.toggleSliderArrowVisibility($slider, $arrowLeft, $arrowRight);

    $arrowLeft.on('click', () => {
        $slider.scrollLeft($slider.scrollLeft() - ($slider.outerWidth() / 2));
    });

    $arrowRight.on('click', () => {
        $slider.scrollLeft($slider.scrollLeft() + ($slider.outerWidth() / 2));
    });
};

SimpleSlider.onScrollEvent = ($slider, $arrowLeft, $arrowRight) => {
    if (IsMobile.mobileOrTablet()) return;

    let hasScrolled = false;
    $slider.on('scroll', () => {
        hasScrolled = true;
    });
    setInterval(() => {
        if (!hasScrolled) return;

        hasScrolled = false;
        SimpleSlider.toggleSliderArrowVisibility($slider, $arrowLeft, $arrowRight);
    }, 250);
};

SimpleSlider.toggleSliderArrowVisibility = ($slider, $arrowLeft, $arrowRight) => {
    const firstItemVisible = $slider.scrollLeft() === 0;
    const lastItemVisible = Math.round($slider[0].scrollWidth - $slider.outerWidth()) - Math.round($slider.scrollLeft()) <= 1;
    $arrowLeft.toggleClass('show', !firstItemVisible);
    $arrowRight.toggleClass('show', !lastItemVisible);
};

SimpleSlider.scrollToSelected = (sliderSelector, selectedSelector) => {
    const $simpleSlider = $(sliderSelector);
    const $slider = $simpleSlider.find('.simple-slider__items');
    const $selected = $slider.find(selectedSelector);
    if (!$selected.length) return;

    $slider.scrollLeft($selected[0].offsetLeft - 64);
};

module.exports = SimpleSlider;
