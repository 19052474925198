'use strict';

const appendToUrl = require('../util/appendToUrl');

const Breadcrumbs = {};

Breadcrumbs.initPLP = () => {
    Breadcrumbs.onClickProductListerTile();
    Breadcrumbs.scrollToStoredPosition();
};

Breadcrumbs.initPDP = () => {
    Breadcrumbs.onBeforeUnload();
    Breadcrumbs.onClickReturnBreadcrumbLink();
    Breadcrumbs.onClickShopTheLookButtonPDP();
};

Breadcrumbs.onClickProductListerTile = () => {
    $('body').on('click', '.js-plp-tile .js-product-tile-link', (event) => {
        event.preventDefault();
        const $productTileLink = $(event.currentTarget);
        let url = $productTileLink.attr('href');
        const $selectedFamilyMemberSize = $('.js-family.selected');
        const $selectedSizes = $('.size-button.selected');
        let preferredSize;
        if ($selectedFamilyMemberSize.length) {
            preferredSize = $selectedFamilyMemberSize.data('size').toString().replace('.', ',');
        } else if ($selectedSizes.length && $selectedSizes.length < 3) {
            preferredSize = $selectedSizes.children().first().text().trim();
        }
        if (preferredSize) {
            url = appendToUrl(url, {
                preferredSize: preferredSize
            });
        }
        Breadcrumbs.storeScrollTopPosition($('html, body').scrollTop());
        Breadcrumbs.storeReturnBreadcrumbUrl(window.location.href);
        window.location.href = url;
    });
};

Breadcrumbs.onClickShopTheLookButtonPDP = () => {
    $('body').on('click', '.carousel-item__product-set-link', (e) => {
        e.preventDefault();
        window.removeEventListener('beforeunload', Breadcrumbs.clearReturnBreadcrumbUrl);
        Breadcrumbs.storeReturnBreadcrumbUrl(window.location.href);
        window.location.href = $(e.currentTarget).prop('href');
    });
};

Breadcrumbs.onClickReturnBreadcrumbLink = () => {
    const breadcrumbUrl = Breadcrumbs.getReturnBreadcrumbUrl();
    if (!breadcrumbUrl) return;

    $('body').on('click', '.js-product-detail .breadcrumb-item:last-of-type a, .product-set-detail.js-product-detail .breadcrumb-item a', (event) => {
        event.preventDefault();
        Breadcrumbs.storeShouldScrollOnPLP();
        window.location.href = breadcrumbUrl;
    });
};

Breadcrumbs.scrollToStoredPosition = () => {
    if (!Breadcrumbs.getShouldScrollOnPLP()) return;

    const scrollTopPosition = Breadcrumbs.getScrollTopPosition();
    if (!scrollTopPosition) return;

    $('html, body').animate({
        scrollTop: scrollTopPosition
    }, 250, 'linear');
    Breadcrumbs.clearShouldScrollOnPLP();
    Breadcrumbs.clearScollTopPosition();
};

Breadcrumbs.clearShouldScrollOnPLP = () => {
    sessionStorage.removeItem('shouldScrollOnPLP');
};

Breadcrumbs.clearScollTopPosition = () => {
    sessionStorage.removeItem('scrollTopPosition');
};

Breadcrumbs.clearReturnBreadcrumbUrl = () => {
    sessionStorage.removeItem('returnBreadcrumbUrl');
};

Breadcrumbs.getShouldScrollOnPLP = () => {
    return sessionStorage.getItem('shouldScrollOnPLP');
};

Breadcrumbs.getScrollTopPosition = () => {
    return sessionStorage.getItem('scrollTopPosition');
};

Breadcrumbs.getReturnBreadcrumbUrl = () => {
    return sessionStorage.getItem('returnBreadcrumbUrl');
};

Breadcrumbs.storeShouldScrollOnPLP = () => {
    sessionStorage.setItem('shouldScrollOnPLP', 'true');
};

Breadcrumbs.storeScrollTopPosition = (position) => {
    sessionStorage.setItem('scrollTopPosition', position);
};

Breadcrumbs.storeReturnBreadcrumbUrl = (href) => {
    sessionStorage.setItem('returnBreadcrumbUrl', href);
};

Breadcrumbs.onBeforeUnload = () => {
    window.addEventListener('beforeunload', Breadcrumbs.clearReturnBreadcrumbUrl);
};

module.exports = Breadcrumbs;
