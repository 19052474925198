'use strict';

let scrollPositionBeforeOpenFlyout = 0;

const PageFlyout = {};

PageFlyout.init = (flyoutSelector, openSelector, openCallback, closeSelector, closeCallback) => {
    PageFlyout.onOpen(flyoutSelector, openSelector, openCallback);
    PageFlyout.onClose(flyoutSelector, closeSelector, closeCallback);
};

PageFlyout.onOpen = (flyoutSelector, openSelector, callback) => {
    $('body').on('click', openSelector, () => {
        PageFlyout.open(flyoutSelector, callback);
    });
};

PageFlyout.open = (flyoutSelector, callback) => {
    scrollPositionBeforeOpenFlyout = $(document).scrollTop();
    $('body').css('top', `-${scrollPositionBeforeOpenFlyout}px`);
    $('body').addClass('page-flyout-open');
    $(flyoutSelector).show();
    setTimeout(() => {
        $(flyoutSelector).addClass('show');
        if (callback) {
            callback();
        }
    }, 200);
};

PageFlyout.onClose = (flyoutSelector, selector, callback) => {
    let closeSelector = `${flyoutSelector} .page-flyout__close, ${flyoutSelector} .page-flyout__overlay`;
    if (selector) {
        closeSelector = `${closeSelector}, ${selector}`;
    }
    $('body').on('click keyup', closeSelector, (event) => {
        if (event.type !== 'click' && event.key !== 'Enter') return;

        PageFlyout.close(flyoutSelector, callback);
    });
};

PageFlyout.close = (flyoutSelector, callback) => {
    $('body').removeClass('page-flyout-open');
    $(flyoutSelector).hide();
    $(flyoutSelector).removeClass('show');
    $(document).scrollTop(scrollPositionBeforeOpenFlyout);
    $('body').css('top', 'unset');
    if (callback) {
        callback();
    }
};

module.exports = PageFlyout;
